<template>
  <div>
    <div class="pt-4">
      <v-form
          ref="quotData"
          v-model="quotEnvio"
          lazy-validation
      >
        <slot name="quoteData"></slot>
      </v-form>
    </div>
    <div class="pt-5">
      <Card_Base>
        <template v-slot:title>
          <v-row>
            <v-col md="4" class="pa-0">
              <v-icon color="white" size="30">las la-box</v-icon>
              Envio
            </v-col>
          </v-row>
        </template>
        <template v-slot:text-card>
          <v-form
              ref="envio"
              v-model="validEnvio"
              lazy-validation
          >
            <v-row>
              <v-col md="12">
                <v-row justify="center">
                  <v-col md="6">
                    <v-autocomplete outlined label="Industria" dense v-model="orders.industryId"
                                    :rules="[v => !!v || 'Industria es requerida']" :items="industries"
                                    item-text="description"
                                    item-value="id" required>
                    </v-autocomplete>
                  </v-col>
                  <v-col md="6">
                    <v-text-field dense outlined type="Number" label="Target" v-model="orders.budget">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col md="6">

                    <v-text-field dense label="Tipo de Comodity" v-model="orders.commodity" :rules="nameRules"
                                  outlined>
                    </v-text-field>

                  </v-col>
                  <v-col md="6">
                    <v-text-field dense label="Costo de mercancía" v-model="orders.GoodsCost" outlined>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </template>
      </Card_Base>
    </div>
    <div class="pt-5">
      <Card_Base>
        <template v-slot:title>
          <v-row>
            <v-col md="4" class="pa-0">
              <v-icon color="white" size="30">las la-th-list</v-icon>
              Detalles de Envio
            </v-col>
          </v-row>
        </template>
        <template v-slot:text-card>
          <v-form
              ref="carga"
              v-model="CargaEnvio"
              lazy-validation
          >
            <load_detail_aereo v-bind:control="TypeService"
                               v-on:loading="loadingData"
                               :edit-mode="Edit.edit"
                               ref="loadDetail"
                               v-bind:loadData="orders.cargo"></load_detail_aereo>
          </v-form>
        </template>
      </Card_Base>
    </div>

    <div class="pt-5">
      <Card_Base>
        <template v-slot:title>
          <v-row>
            <v-col md="4" class="pa-0">
              <v-icon color="white" size="30">las la-toolbox</v-icon>
              Servicios
            </v-col>
          </v-row>
        </template>
        <template v-slot:text-card>
          <ServicoAereo
              :incoterm="Inconterm"
              v-bind:Services_="Services"
              :type="Type"
              ref="services"
              v-on:data="recibeData"
          ></ServicoAereo>
        </template>
      </Card_Base>
    </div>

    <div class="pt-5">
      <Card_Base>
        <template v-slot:title>

          <v-row>
            <v-col md="4" class="pa-0">
              <v-icon color="white" size="30">las la-user</v-icon>
              Informacion del Cliente
            </v-col>
          </v-row>
        </template>
        <template v-slot:text-card>
          <v-form
              ref="cliente"
              v-model="ClientEnvio"
              lazy-validation
          >
            <v-row dense class="offset-2">


              <v-col md="6">
                <v-row style="text-align: center">
                  <v-col md="8">
                    <v-autocomplete label="Cliente" v-model="orders.clientId" dense outlined
                                    :search-input.sync="clientSearch"
                                    :items="companies"
                                    item-value="id" item-text="name" :rules="[v => !!v || 'Cliente es requerido']">

                    </v-autocomplete>
                    <v-btn color="error" outlined x-small @click="getCustomers">
                      Cargar Clientes
                    </v-btn>
                  </v-col>

                  <v-col md="2">
                    <v-dialog v-model="showAddCompany" width="1000">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="mt-1" v-on="on" v-bind="attrs">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="primary" icon fab size="34" v-bind="attrs"
                                      v-on="on">mdi-plus-circle
                              </v-icon>
                            </template>
                            <span>Agregar</span>
                          </v-tooltip>
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="primary white--text">
                          <v-icon color="white" left>mdi-table-edit</v-icon>
                          Añadir Cliente
                        </v-card-title>
                        <Customers v-on:update="getCustomers()"/>
                      </v-card>
                    </v-dialog>
                  </v-col>

                  <v-col md="6">
                    <label>Frecuencia Mensual</label>
                    <br><br>
                    <v-slider v-model="orders.Frequency" :thumb-size="18" thumb-label="always" min="1"></v-slider>
                  </v-col>
                </v-row>


              </v-col>

              <v-col md="6">
                <v-row>
                  <v-col md="8" >
                    <v-autocomplete v-if="!orders.assignToPricer" disabled label="Pricer" dense outlined
                                    v-model="orders.pricerId" :items="Employees"
                                    :item-text="item => item.firstName +' '+ item.lastName" item-value="userId">
                    </v-autocomplete>

                    <v-autocomplete v-else label="Pricer" dense outlined v-model="orders.pricerId" :items="Employees"
                                    :item-text="item => item.firstName +' '+ item.lastName" item-value="userId"
                                    :rules="[v => !!v || 'Pricer es requerido']">
                    </v-autocomplete>
                  </v-col>

                  <v-col md="4">
                    <v-label>Cotizar con pricer</v-label>
                    <v-switch v-model="orders.assignToPricer" dense color="primary" inset></v-switch>
                  </v-col>


                </v-row>


                <v-label>¿Urgente?</v-label>
                <v-switch v-model="orders.urgent" dense color="primary" inset></v-switch>
              </v-col>


            </v-row>
          </v-form>
        </template>
      </Card_Base>
    </div>

    <div class="pt-5">
      <Card_Base>
        <template v-slot:title>
          <v-row>

            <v-col md="4" class="pa-0">
              <v-icon color="white" size="30">las la-comments</v-icon>
              Comentarios Adicionales
            </v-col>
          </v-row>
        </template>

        <template v-slot:text-card>
          <v-row justify="center">
            <template>
              <v-btn
                  v-show="!hidden"
                  color="primary"
                  fab
                  dark
                  small
                  absolute
                  bottom
                  right
              >
                <v-file-input
                    counter
                    hide-input
                    multiple
                    show-size
                    truncate-length="15"
                    class="mb-4 ml-2"
                ></v-file-input>
              </v-btn>
            </template>

            <v-col md="10">
              <br>
              <v-textarea counter="256" @keydown="txtMaxLenght" @keyup="txtMaxLenght" outlined label="comentarios"
                          v-model="orders.comments"></v-textarea>
            </v-col>
            <v-col md="10">
              <file-drag v-model="orders.documentsDrag" @change="ConvertFile()"></file-drag>
            </v-col>

            <v-col
                class="d-flex"
                cols="3"
                sm="6"
            >
              <v-select
                  :items="business"
                  label="Empresa"
                  v-model="orders.businessId"
                  item-text="name"
                  item-value="id"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end">
            <v-col cols="3" class="pb-0">
              <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date"
                      label="Vencimiento de cotizacion"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" no-title scrollable color="primary">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </template>
      </Card_Base>
    </div>

    <v-row class="pt-3" style="text-align-last: center">
      <v-col>
        <v-btn x-large color="primary" dark @click="NewOrder">
          <slot name="btn-quote">
            ¡Solicita tu Cotizacion!
          </slot>
        </v-btn>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import Card_Base from "@/components/Quote/QuotDetail/Card_Base";
import Customers from "@/components/Accounts_Admin/Customers";
import load_detail_aereo from "@/components/Quote/QuotDetail/load_detail_aereo";
import ServicoAereo from "./QuotDetail/ServicoAereo";
import FileDrag from "@/components/Global/FileDrag/FileDrag";
import debouncePromise from "debounce-promise";

export default {
  name: "Aereo",
  components: {
    ServicoAereo,
    load_detail_aereo,
    Customers,
    Card_Base,
    FileDrag

  },
  props: {
    Typetransportation: Number,
    TypeService: Number,
    Inconterm: Number,
    Type: Number,
    Origin: {},
    DataOrder: {},
    Destination: {},
    LoadData: Array,
    OriginPort: {},
    filterRoute:Number,
    DestinationPort: {},
    dueDate: String,
    AutocompleteOrigin: Boolean,
    AutocompleteDestination: Boolean,
    Services: Object,
    Edit: {
      type: Object,
      default: function () {
        return {
          edit: false,
          idOrder: null,
        }
      }
    },
  },
  watch: {
    originAddressSearch() {
      this.googleSearch();
    },
    Servicedef: function (val) {
      if (val == 'NA') {
        this.$toast.error("Es necesario elegir un servicio porfavor contacte un asesor")

      }
    },
    clientSearch: {
      handler(val){
        const selectedClient = this.companies.find(x => x.name == val)
        if(selectedClient?.name == val) return
        if(val) this.searchClients(this.clientSearch)
      }
    },
  },
  data() {
    return {
      date: null,
      menu2: false,
      business:[],
      filess: [],
      clientSearch: null,
      businessId:Number,
      hidden: true,
      show: 1,
      showAddCompany: false,
      IMO: null,
      valid: true,
      ClientEnvio: true,
      validEnvio: true,
      CargaEnvio: true,
      quotEnvio: true,
      industries: [],
      NamePricer: "",
      nameRules: [
        v => !!v || 'Nombre es requerido',
      ],
      BudgetRules: [
        v => !!v || 'Target es requerido',
      ],
      ClientRules: [
        v => !!v || 'Cliente es requerido',
      ],
      PricerRules: [
        v => !!v || 'Pricer es requerido',
      ],
      transporteMap: "",
      transport: "",
      transportation: "Maritimo",
      serviceMap: "",
      IncotermMap: "",
      tipoEnvio: "",
      Employees: [],
      companies: [],
      clientSearchLoading: false,
      // Servicios: [],
      resulImage: "",
      image00000: 1,
      image00001: 0,
      image00011: 0,
      image00101: 0,
      tab: null,
      Pickup: 0,
      Delivery: 0,
      PortFees: 0,
      Customclerance: 0,
      Logistic: 0,
      Insurance: 0,
      switch1: "",
      toggle_exclusive: undefined,
      ex11: false,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      filterOptions: false,
      origin: {},
      destination: {},
      markers: [],
      originAddress: {},
      TypeRoute: ['Importacion', 'Exportacion'],
      RangePrices: ['1- 100 USD', '100 - 300 USD', '300 - 1000', '> 1000'],
      commodities: ['Agricultura', 'Alimentación', 'Comercio', 'Construcción', 'Educación'],
      price: 0,
      commodity: '',
      portsOfLoading: [],
      Incoterms: [],
      Pol: {
        name: null,
        city: null,
        coordinates: null,
        country: null
      },
      Pod: {
        name: null,
        city: null,
        coordinates: null,
        country: null
      },
      detailorder: null,
      LoadingTypes: [],
      address: '',
      address2: '',
      Destiny: null,

      menu: false,
      Incoterm: "",
      transportedef: "",

      orders: {
        businessId:1,
        transportationId: null,
        transportationCategoryId: null,
        assignToPricer: true,
        incotermId: null,
        GoodsCost: 0,
        DeliveryTypeId:null,
        orderTypeId: null,
        origin: [{
          address: null,
          city: null,
          state: null,
          country: null,
          coordinates: null
        }],
        destination: [{
          address: null,
          state: null,
          city: null,
          country: null,
          coordinates: null
        }],
        pol: null,
        pod: null,
        departureDate: null,
        industryId: null,
        budget: null,
        commodity: null,
        insideBillOfLanding: false,
        cargo: [],
        Services: [],
        clientId: null,
        pricerId: null,
        Frequency: null,
        urgent: false,
        comments: null,
        documents:[],
        documentsDrag:[],
        orderStatusLifetime:[]
      },
      TypeRoutedef: "",
      transporte: ['Aereo', 'Maritimo', 'Terrestre'],
      Servicedef: "",
      Service: ['LCL', 'FCL', 'Bulk', 'NA'],
      items: [
        {title: 'Click Me'},
        {title: 'Click Me'},
        {title: 'Click Me'},
        {title: 'Click Me 2'},
      ],

      expand: false,
      slider: 45,
      rules: {
        txtMaxLenght: value => (value || '').length <= 200 || 'Description must be 200 characters or less'
      },
    }
  },
  methods: {
    searchClients: debouncePromise(function (search){
      if (search === '') {
        return
      }
      this.getClients(search)
    }, 300),
    getClients(search) {
      this.clientSearchLoading = true
      this.companies = []
      this.$http.get(`Companies/find`,{
            params: {
              name: search,
            }
          }
      ).then((response) => {
        console.log(response)
        this.clientSearchLoading = false
        let aux = response.data.data
        if (aux === null){
          console.log(aux)
        } else {
          aux.forEach((element) =>{
            this.companies.push(element)
          })
        }
      })
    },
    getBuissnes() {
      this.$store.dispatch("working", true);
      this.$http("Business").then((response) => {
        this.business = response.data.data;
        console.log(this.business)
      }).catch((error) => {
        console.log(error);
        this.$toast.error("Algo salio mal, contacte al administrador!");
      }).finally(()=>{
        this.$store.dispatch("working", false);
      })

    },


    ConvertFile: async function () {

      let documents = this.orders.documentsDrag;

      for (const doc of documents) {
        const result = await this.UploadFile(doc);
        const data = result.split(',')[1];
        const dotPosition = doc.name.lastIndexOf('.');
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName = dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);
        this.orders.documents.push(
            {
              documentTypeId: 103,
              file: data,
              fileName: fileName,
              fileExtension: fileExt
            }
        )
      }
      console.log(this.orders)
    },
    UploadFile: function (file) {
      return new Promise((resolve, reject) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error);
      });
    },

    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */

    txtMaxLenght: function (e) {
      this.txtAreaLenght = this.orders.comments.length
      this.txtAreaLenght >= this.txtAreaMax ? this.orders.comments = this.orders.comments.slice(0, this.txtAreaMax) : ''
      if (this.txtAreaLenght === this.txtAreaMax) {
        if (e.keyCode !== 8 && e.keyCode !== 67 && e.keyCode !== 37 && e.keyCode !== 38 && e.keyCode !== 39 && e.keyCode !== 40) {
          if (e.keyCode !== 17) {
            e.preventDefault()
          } else {
            if (e.keyCode === 67) {
              this.orders.comments = document.execCommand('copy')
            }
          }
          e.preventDefault()
        }
      }
    },

    recibeData: function (servicesData) {
      console.log("servicio recibido", servicesData);
      this.orders.Services = servicesData;
      if(this.orders.services?.length > 0){
        this.orders.Services.forEach(item => {
          let service = this.orders.services.find(el => item.ServiceId === el.serviceId);
          if(service){
            item.id = service.id;
          }
        })
      }
    },

    loadingData: function (loadingData) {
      console.log(loadingData);
      this.orders.cargo = [];
      loadingData.forEach(element => {
        this.orders.cargo.push(
            {
              Id: element.id,
              stackable: element.stackable,
              tiltable: element.tiltable,
              containerId: element.containerId > 0 ? element.containerId : null,
              type: element.type,
              quantity: element.quantity,
              UnitMeasure: element.unitMeasure,
              WeightUnitMeasure: element.weightUnitMeasure,
              weight: element.weight,
              loadingTypeId: element.loadingTypeId > 0 ? element.loadingTypeId : null,
              dangerous: element.dangerous,
              large: element.large,
              width: element.width,
              legal: true,
              totalWeight:element.totalWeight,
              height: element.height,
              Unit: element.weightUnitMeasure,
              messageWeigth: '',
              documents: element.documents??[],
              full: false,
            }
        )
      })
    },

    QuotDataReceive: function () {

    },

    lastNameEvent: function (value) {
      console.log(value)
    },

    test: function (data) {
      console.log("a", data);
    },


    getPricer: function () {
      this.$store.dispatch('working', true)

      this.$http.get('Employees/Pricing')
          .then(response => {
            this.Employees = response.data.data;
            console.log(this.Employees)
          })
          .catch(error => {
            console.log(error)
          }).finally(() => this.$store.dispatch('working', false))
    },


    getIndustries: function () {
      this.$store.dispatch('working', true)

      this.$http.get('Industries')
          .then(response => {
            this.industries = response.data.data;
          })
          .catch(error => {
            console.log(error)
          }).finally(() => this.$store.dispatch('working', false))
    },

    SetDefaultOrder() {
      this.Origin = {};
      this.Destination = {};
      this.orders = {
        transportationId: null,
        transportationCategoryId: null,
        assignToPricer: true,
        incotermId: null,
        GoodsCost: 0,
        orderTypeId: null,
        origin: [{
          address: null,
          city: null,
          state: null,
          country: null,
          coordinates: null
        }],
        destination: [{
          address: null,
          state: null,
          city: null,
          country: null,
          coordinates: null
        }],
        pol: null,
        pod: null,
        departureDate: null,
        industryId: null,
        budget: null,
        commodity: null,
        insideBillOfLanding: false,
        cargo: [],
        Services: [],
        clientId: null,
        pricerId: null,
        Frequency: null,
        urgent: false,
        comments: null
      }
    },

    NewOrder: function () {
      delete this.orders.orderStatusLifetime
      console.log(this.orders)
      if (this.Typetransportation === null) {
        this.$toast.error("Porfavor llena un Transporte principal");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.TypeService === null) {
        this.$toast.error("Porfavor llena la categoria del transporte");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.Type === null) {
        this.$toast.error("Sera impo o expo?");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.Inconterm === null) {
        this.$toast.error("Que incoterm sera tu cotizacion?");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.orders.clientId === null) {
        this.$toast.error("Porfavor llena un Cliente!");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.orders.industryId === null) {
        this.$toast.error("Porfavor llena una industria!");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.orders.commodity === null) {
        this.$toast.error("Porfavor llena un commodity");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.orders.businessId === null) {
        this.$toast.error("Porfavor llena una Empresa!");
        this.$store.dispatch("working", false);
        return;
      }

      this.orders.ExpirationDate = this.date
      this.orders.transportationId = this.Typetransportation;
      this.orders.transportationCategoryId = this.TypeService;
      this.orders.orderTypeId = this.Type;
      this.orders.incotermId = this.Inconterm;
      this.orders.departureDate = this.dueDate;

      if (this.orders.insideBillOfLanding == "true")
        this.orders.insideBillOfLanding = true;

      if (this.orders.insideBillOfLanding == "false")
        this.orders.insideBillOfLanding = false;


      this.orders.cargo.forEach(element => {
        if (!element.dangerous) {
          delete element.documents
        }
        if (element.dangerous && element.documents) {
          element.documents.forEach(data => {
            if (data.file == null) {
              let index = element.documents.findIndex(index => index.file == data.file);
              element.documents.splice(index, 1);
            }
          })
        }

        if (element.type == "Container"){
          element.loadingTypeId = null
          // element.height = 0;
          // element.large = 0;
          // element.width = 0;
        }

        if (element.type=="Pallets"){
          element.containerId = null;
          element.loadingTypeId = 2;
        }

      })
      this.orders.DeliveryTypeId = this.filterRoute;

      let dataSend = JSON.parse(JSON.stringify(this.orders));
      delete dataSend.services;
      delete dataSend.transportation;
      delete dataSend.transportationCategory;
      delete dataSend.industry;
      delete dataSend.frequency;
      delete dataSend.currency;
      delete dataSend.orderType;
      delete dataSend.client;
      delete dataSend.salesManager;
      delete dataSend.pricer;
      delete dataSend.history;
      delete dataSend.documentsDrag;
      delete dataSend.orderStatusLifetime;

      if(this.Edit.edit) {
        if(
            this.$refs.envio.validate()
            && this.$refs.cliente.validate()
            && this.$refs.carga.validate()
        ) {
          console.log("UpdateOrder")
          this.$emit("UpdateOrder", JSON.parse(JSON.stringify(this.orders)));
        }
        else{
          this.$toast.error('Llene los campos requeridos');
        }

        return
      }
      // console.log('Actualiza...');
      this.$refs.envio.validate();
      this.$refs.cliente.validate();
      this.$refs.carga.validate();
      this.$refs.quotData.validate();

      if (this.$refs.envio.validate() && this.$refs.cliente.validate() && this.$refs.carga.validate() && this.$refs.quotData.validate() && Object.keys(this.Origin).length > 0 && Object.keys(this.Destination).length > 0) {
        this.$store.dispatch("working", true);
        if (this.orders.budget == null || this.orders.budget == "")
          delete this.orders.budget;

        this.orders.GoodsCost = parseInt(this.orders.GoodsCost)

        this.orders.origin[0].country = this.Origin.country
        this.orders.origin[0].city = this.Origin.city
        this.orders.origin[0].address = this.Origin.address
        this.orders.origin[0].state = this.Origin.state
        this.orders.origin[0].coordinates = `${this.Origin.coordinates.lat},${this.Origin.coordinates.lng}`

        this.orders.destination[0].country = this.Destination.country
        this.orders.destination[0].city = this.Destination.city
        this.orders.destination[0].address = this.Destination.address
        this.orders.destination[0].state = this.Destination.state
        this.orders.destination[0].coordinates = `${this.Destination.coordinates.lat},${this.Destination.coordinates.lng}`

        if(Object.keys(this.OriginPort).length>0 && Object.keys(this.DestinationPort).length>0){

          if (this.filterRoute==2){


            this.orders.pol = {}
            this.orders.pol.country = this.OriginPort.country
            this.orders.pol.city = this.OriginPort.city
            this.orders.pol.name = this.OriginPort.name
            this.orders.pol.coordinates = `${this.OriginPort.location.coordinates[0]},${this.OriginPort.location.coordinates[1]}`

            this.orders.origin[0].country = this.orders.pol.country
            this.orders.origin[0].city = this.orders.pol.city
            this.orders.origin[0].address = this.Origin.address
            this.orders.origin[0].state = this.Origin.state
            this.orders.origin[0].coordinates = this.orders.pol.coordinates

            this.orders.pod = {}
            this.orders.pod.country = this.DestinationPort.country
            this.orders.pod.city = this.DestinationPort.city
            this.orders.pod.name = this.DestinationPort.name
            this.orders.pod.coordinates = `${this.DestinationPort.location.coordinates[0]},${this.DestinationPort.location.coordinates[1]}`

            this.orders.destination[0].country = this.orders.pod.country
            this.orders.destination[0].city = this.orders.pod.city
            this.orders.destination[0].address = this.Destination.address
            this.orders.destination[0].state = this.Destination.state
            this.orders.destination[0].coordinates = this.orders.pod.coordinates

          }

          if (this.filterRoute == 4 || this.filterRoute == 3 || this.filterRoute==1){
            this.orders.pol = {}
            this.orders.pol.country = this.OriginPort.country
            this.orders.pol.city = this.OriginPort.city
            this.orders.pol.name = this.OriginPort.name
            this.orders.pol.coordinates = `${this.OriginPort.location.coordinates[0]},${this.OriginPort.location.coordinates[1]}`

            this.orders.pod = {}
            this.orders.pod.country = this.DestinationPort.country
            this.orders.pod.city = this.DestinationPort.city
            this.orders.pod.name = this.DestinationPort.name
            this.orders.pod.coordinates = `${this.DestinationPort.location.coordinates[0]},${this.DestinationPort.location.coordinates[1]}`
          }

        }

        if(Object.keys(this.OriginPort).length>0 && Object.keys(this.DestinationPort).length==0){

            this.orders.pol = {}
            this.orders.pol.country = this.OriginPort.country
            this.orders.pol.city = this.OriginPort.city
            this.orders.pol.name = this.OriginPort.name
            this.orders.pol.coordinates = `${this.OriginPort.location.coordinates[0]},${this.OriginPort.location.coordinates[1]}`

            this.orders.origin[0].country = this.orders.pol.country
            this.orders.origin[0].city = this.orders.pol.city
            this.orders.origin[0].address = this.Origin.address
            this.orders.origin[0].state = this.Origin.state
            this.orders.origin[0].coordinates = `${this.OriginPort.location.coordinates[0]},${this.OriginPort.location.coordinates[1]}`

        }

        if(Object.keys(this.OriginPort).length==0 && Object.keys(this.DestinationPort).length>0){


            this.orders.pod = {}
            this.orders.pod.country = this.DestinationPort.country
            this.orders.pod.city = this.DestinationPort.city
            this.orders.pod.name = this.DestinationPort.name
            this.orders.pod.coordinates = `${this.DestinationPort.location.coordinates[0]},${this.DestinationPort.location.coordinates[1]}`


            this.orders.destination[0].country = this.orders.pod.country
            this.orders.destination[0].city = this.orders.pod.city
            this.orders.destination[0].address = this.Destination.address
            this.orders.destination[0].state = this.Destination.state
            this.orders.destination[0].coordinates = `${this.DestinationPort.location.coordinates[0]},${this.DestinationPort.location.coordinates[1]}`


        }




        /* if (Object.keys(this.OriginPort).length > 0) {
           console.log(this.OriginPort)
           if (this.filterRoute==2){
             this.orders.pol = {}
             this.orders.pol.country = this.OriginPort.country
             this.orders.pol.city = this.OriginPort.city
             this.orders.pol.name = this.OriginPort.name
             this.orders.pol.coordinates = `${this.OriginPort.location.coordinates[0]},${this.OriginPort.location.coordinates[1]}`

             this.orders.origin[0].country = this.orders.pol.country
             this.orders.origin[0].city = this.orders.pol.city
             this.orders.origin[0].address = this.Origin.address
             this.orders.origin[0].state = this.Origin.state
             this.orders.origin[0].coordinates = `${this.OriginPort.location.coordinates[0]},${this.OriginPort.location.coordinates[1]}`
           }
           if (this.filterRoute==4){
             this.orders.pol = {}
             this.orders.pol.country = this.OriginPort.country
             this.orders.pol.city = this.OriginPort.city
             this.orders.pol.name = this.OriginPort.name
             this.orders.pol.coordinates = `${this.OriginPort.location.coordinates[0]},${this.OriginPort.location.coordinates[1]}`
           }



           }


         if (Object.keys(this.DestinationPort).length > 0) {
           console.log(this.DestinationPort)
             if (this.filterRoute==2){
               this.orders.pod = {}
               this.orders.pod.country = this.DestinationPort.country
               this.orders.pod.city = this.DestinationPort.city
               this.orders.pod.name = this.DestinationPort.name
               this.orders.pod.coordinates = `${this.DestinationPort.location.coordinates[0]},${this.DestinationPort.location.coordinates[1]}`

               this.orders.destination[0].country = this.orders.pod.country
               this.orders.destination[0].city = this.orders.pod.city
               this.orders.destination[0].address = this.Destination.address
               this.orders.destination[0].state = this.Destination.state
               this.orders.destination[0].coordinates = this.orders.pod.coordinates = `${this.DestinationPort.location.coordinates[0]},${this.DestinationPort.location.coordinates[1]}`

             }
             if(this.filterRoute==4){
               this.orders.pod = {}
               this.orders.pod.country = this.DestinationPort.country
               this.orders.pod.city = this.DestinationPort.city
               this.orders.pod.name = this.DestinationPort.name
               this.orders.pod.coordinates = `${this.DestinationPort.location.coordinates[0]},${this.DestinationPort.location.coordinates[1]}`
             }



           }*/


        this.orders.transportationId = this.Typetransportation;
        this.orders.transportationCategoryId = this.TypeService;
        this.orders.orderTypeId = this.Type;
        this.orders.incotermId = this.Inconterm;

        this.orders.departureDate = this.dueDate;

        if (this.orders.insideBillOfLanding == "true")
          this.orders.insideBillOfLanding = true;

        if (this.orders.insideBillOfLanding == "false")
          this.orders.insideBillOfLanding = false;

        this.orders.cargo.forEach(element => {
          if (!element.dangerous) {
            delete element.documents
          }

          if (element.dangerous && element.documents) {
            element.documents.forEach(data => {
              if (data.file == null) {
                let index = element.documents.findIndex(index => index.file == data.file);
                element.documents.splice(index, 1);
              }
            })
          }


          if (element.type == "Container"){
            element.loadingTypeId = null
            element.height = 0;
            element.large = 0;
            element.width = 0;
          }

          if (element.type=="Pallets"){
            element.containerId = null;
            element.loadingTypeId = 2;
          }


        })

        this.orders.DeliveryTypeId = this.filterRoute;


        let dataSend = JSON.parse(JSON.stringify(this.orders));
        delete dataSend.services;
        delete dataSend.transportation;
        delete dataSend.transportationCategory;
        delete dataSend.industry;
        delete dataSend.frequency;
        delete dataSend.currency;
        delete dataSend.orderType;
        delete dataSend.client;
        delete dataSend.salesManager;
        delete dataSend.pricer;
        delete dataSend.history;
        delete dataSend.orderStatusLifetime;



        if (this.Edit.edit) {

          console.log(dataSend, 'soy datasend')
          dataSend.Transportation = dataSend.transportation
          this.$http.put(`Orders/${this.Edit.idOrder}`, dataSend).then(response => {

            if (response.data.code === 200) {
              delete this.datasend.orderStatusLifetime
              console.log(response.data.data);
              this.$toast.info("Cotizacion Actualizada");
              this.$emit('OrderUpdated');
              this.$refs.loadDetail.resetCargo();
              //this.SetDefaultOrder();
            } else {
              this.$toast.error("¡Ups! No pasó tu cotización. Nuestro equipo ya fue notificado")
            }
          }).catch(error => {
            this.$toast.error("¡Ups! No pasó tu cotización. Nuestro equipo ya fue notificado")
            console.log(error);
          }).finally(() => {
            this.$store.dispatch('working', false)
          })
        } else if (this.Edit.edit == false) {
          this.$http.post('Orders', dataSend).then(response => {

            if (response.data.code === 200) {
              console.log(response.data.data);
              this.$toast.success("Cotizacion Enviada a Pricing")
              this.$emit('OrderCreated', response.data.data);
              if (!this.orders.assignToPricer)
                this.$router.replace(`/ProceedQuoteSales/${response.data.data.id}`);
              this.$refs.loadDetail.resetCargo();
              this.$refs.services.resetServices();
              this.SetDefaultOrder();

            } else {
              this.$toast.error("¡Ups! No pasó tu cotización. Nuestro equipo ya fue notificado")
            }
          }).catch(error => {
            this.$toast.error("¡Ups! No pasó tu cotización. Nuestro equipo ya fue notificado")
            console.log(error);
          }).finally(() => {
            this.$store.dispatch('working', false)
          })
        }
      } else {
        this.$toast.error('Llene origen o destino');

      }


    },
    getCustomers: async function () {
      try {
        const response = await this.$http.get('Companies');
        if (response && response.data) {
          this.companies = response.data.data;
        } else {
          console.error('Respuesta no válida:', response);
        }
      } catch (error) {
        console.error('Error al obtener clientes:', error);
      }
    },
    updateCustomers: function () {
      console.log('hola')
    },

    SetDataOrder() {


      if (this.DataOrder) {
        this.orders = this.DataOrder;
        this.orders.urgent = this.DataOrder.urgent;
        console.log(this.orders);
      }


    }

  },
  mounted() {
    this.getCustomers();
    this.getPricer();
    this.QuotDataReceive();
    this.getIndustries();
    this.SetDataOrder();
    this.getBuissnes();
  }
}

</script>

<style scoped>

</style>
