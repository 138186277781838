<template>
  <div>
    <div class="pt-4">
      <v-form
          ref="quotData"
          v-model="quotEnvio"
          lazy-validation
      >
        <slot name="quoteData"></slot>
      </v-form>
    </div>
    <div class="pt-5">
      <Card_Base>
        <template v-slot:title>
          <v-row>
            <v-col md="4" class="pa-0">
              <v-icon color="white" size="30">las la-box</v-icon>
              Envio
            </v-col>
          </v-row>
        </template>
        <template v-slot:text-card>
          <v-form
              ref="envio"
              v-model="validEnvio"
              lazy-validation
          >
            <v-row>
              <v-col md="12">
                <v-row justify="center">
                  <v-col md="6">
                    <v-autocomplete outlined
                                    label="Industria"
                                    dense
                                    v-model="orders.industryId"
                                    :rules="[v => !!v || 'Item is required']"
                                    :items="industries"
                                    item-text="description"
                                    item-value="id">
                    </v-autocomplete>
                  </v-col>
                  <v-col md="6">
                    <v-autocomplete v-if="TypeService != 2"
                                    outlined
                                    label="Land Service"
                                    dense
                                    ref="services"
                                    v-model="orders.landServiceId"
                                    :rules="[v => !!v || 'Land service es requerido']"
                                    :items="LandService"
                                    item-text="Description"
                                    item-value="Id"
                                    :required="TypeService!=2">
                    </v-autocomplete>

                  </v-col>

                </v-row>
                <v-row justify="center">
                  <v-col md="6">

                    <v-text-field dense label="Tipo de Comodity" v-model="orders.commodity" :rules="nameRules"
                                  outlined>
                    </v-text-field>

                  </v-col>
                  <v-col md="6">
                    <v-text-field dense outlined type="Number" label="Target" v-model="orders.budget">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-col md="6">
                  <v-checkbox class="mt-0"
                              v-if="TypeService == 2"
                              color="primary"
                              label="Transbordo"
                              v-model="orders.LandTransfer"></v-checkbox>
                </v-col>
              </v-col>
            </v-row>
          </v-form>

        </template>
      </Card_Base>
    </div>
    <div class="pt-5">
      <Card_Base>
        <template v-slot:title>
          <v-row>
            <v-col md="4" class="pa-0">
              <v-icon color="white" size="30">las la-th-list</v-icon>
              Detalles de Envio
            </v-col>
          </v-row>
        </template>
        <template v-slot:text-card>
          <v-form
              ref="carga"
              v-model="CargaEnvio"
              lazy-validation
          >
            <Load_detail_terrestre2 v-bind:control="TypeService"
                                    v-bind:loadDetail="DataService"
                                    v-on:loading="loadingData"
                                    :edit-mode="Edit.edit"
                                    ref="loadDetailTerrestre"
                                    v-bind:loadData="orders.cargo"></Load_detail_terrestre2>
          </v-form>
        </template>
      </Card_Base>
    </div>
    <div class="pt-5">
      <Card_Base>
        <template v-slot:title>
          <v-row>
            <v-col md="4" class="pa-0">
              <v-icon color="white" size="30">las la-toolbox</v-icon>
              Servicios
            </v-col>
          </v-row>
        </template>
        <template v-slot:text-card>
          <ServicosTerrestres
              ref="servTerrestre"
              :incoterm="Inconterm"
              @serviceFields="getServiceFields"
              v-bind:Services_="Services"
              :type="Type"
              v-on:data="recibeData"
          ></ServicosTerrestres>
        </template>
      </Card_Base>
    </div>
    <div class="pt-5">
      <Card_Base>
        <template v-slot:title>
          <v-row>
            <v-col md="4" class="pa-0">
              <v-icon color="white" size="30">las la-user</v-icon>
              Informacion del Cliente
            </v-col>
          </v-row>
        </template>
        <template v-slot:text-card>
          <v-form
              ref="cliente"
              v-model="ClientEnvio"
              lazy-validation
          >
            <v-row dense class="offset-2">


              <v-col md="6">
                <v-row style="text-align: center">
                  <v-col md="8">
                    <v-autocomplete label="Cliente" v-model="orders.clientId" dense outlined :items="companies"
                                    :search-input.sync="clientSearch"
                                    item-value="id" item-text="name" :rules="[v => !!v || 'Cliente es requerido']">

                    </v-autocomplete>
                    <v-btn color="error" outlined x-small @click="getCustomers">
                      Cargar Clientes
                    </v-btn>
                  </v-col>

                  <v-col md="2">
                    <v-dialog v-model="showAddCompany" width="1000">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="mt-1" v-on="on" v-bind="attrs">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="primary" icon fab size="34" v-bind="attrs"
                                      v-on="on">mdi-plus-circle
                              </v-icon>
                            </template>
                            <span>Agregar</span>
                          </v-tooltip>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="primary white--text">
                          <v-icon color="white" left>mdi-table-edit</v-icon>
                          Añadir Cliente
                        </v-card-title>
                        <Customers v-on:update="getCustomers()"/>
                      </v-card>
                    </v-dialog>
                  </v-col>

                  <v-col md="6">
                    <label>Frecuencia Mensual</label>
                    <br><br>
                    <v-slider v-model="orders.Frequency" :thumb-size="18" thumb-label="always" min="1"></v-slider>
                  </v-col>
                </v-row>


              </v-col>

              <v-col md="6">
                <v-row>
                  <v-col md="8">
                    <v-autocomplete v-if="!orders.assignToPricer" disabled label="Pricer" dense outlined
                                    v-model="orders.pricerId" :items="Employees"
                                    :item-text="item => item.firstName +' '+ item.lastName" item-value="userId">
                    </v-autocomplete>

                    <v-autocomplete v-else label="Pricer" dense outlined v-model="orders.pricerId" :items="Employees"
                                    :item-text="item => item.firstName +' '+ item.lastName" item-value="userId"
                                    :rules="[v => !!v || 'Pricer es requerido']">
                    </v-autocomplete>
                  </v-col>

                  <v-col md="4">
                    <v-label>Cotizar con pricer</v-label>
                    <v-switch v-model="orders.assignToPricer" dense color="primary" inset></v-switch>
                  </v-col>


                </v-row>


                <v-label>¿Urgente?</v-label>
                <v-switch v-model="orders.urgent" dense color="primary" inset></v-switch>
              </v-col>


            </v-row>
          </v-form>
        </template>
      </Card_Base>
    </div>

    <div class="pt-5">
      <Card_Base>
        <template v-slot:title>
          <v-row>

            <v-col md="4" class="pa-0">
              <v-icon color="white" size="30">las la-comments</v-icon>
              Comentarios Adicionales
            </v-col>
          </v-row>
        </template>

        <template v-slot:text-card>
          <v-row justify="center">
            <template>
              <v-btn
                  v-show="!hidden"
                  color="primary"
                  fab
                  dark
                  small
                  absolute
                  bottom
                  right
              >
                <v-file-input
                    counter
                    hide-input
                    multiple
                    show-size
                    truncate-length="15"
                    class="mb-4 ml-2"
                ></v-file-input>
              </v-btn>
            </template>

            <v-col md="10">
              <br>
              <v-textarea counter="256" @keydown="txtMaxLenght" @keyup="txtMaxLenght" outlined label="comentarios"
                          v-model="orders.comments"></v-textarea>
            </v-col>
            <v-col md="10">
              <file-drag v-model="orders.documentsDrag" @change="ConvertFile()"></file-drag>
            </v-col>
            <v-col
                class="d-flex"
                cols="3"
                sm="6"
            >
              <v-select
                  :items="business"
                  label="Empresa"
                  v-model="orders.businessId"
                  item-text="name"
                  item-value="id"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end">
            <v-col cols="3" class="pb-0">
              <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date"
                      label="Vencimiento de cotizacion"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" no-title scrollable color="primary">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </template>
      </Card_Base>
    </div>

    <v-row class="pt-3" style="text-align-last: center">
      <v-col>
        <v-btn x-large color="primary" dark @click="NewOrder">
          <slot name="btn-quote">
            ¡Solicita tu Cotizacion!
          </slot>
        </v-btn>
      </v-col>
    </v-row>

  </div>

</template>

<script>
import Card_Base from "@/components/Quote/QuotDetail/Card_Base";
import Customers from "@/components/Accounts_Admin/Customers";
import Load_detail_terrestre2 from "@/components/Quote/QuotDetail/load_detail_terrestre2";
import ServicosTerrestres from "@/components/Quote/QuotDetail/ServicosTerrestres";
import FileDrag from "@/components/Global/FileDrag/FileDrag";
import debouncePromise from "debounce-promise";

export default {
  name: "Terrestre",
  components: {
    Load_detail_terrestre2,
    ServicosTerrestres,
    Customers,
    FileDrag,
    Card_Base,

  },
  props: {
    Typetransportation: Number,
    TypeService: Number,
    Inconterm: Number,
    Type: Number,
    Origin: Object,
    DataOrder: Object,
    Destination: Object,
    DataService: Object,
    LoadData: Array,
    OriginPort: Object,
    DestinationPort: Object,
    dueDate: String,
    AutocompleteOrigin: Boolean,
    AutocompleteDestination: Boolean,
    Services: Object,
    Edit: {
      type: Object,
      default: function () {
        return {
          edit: false,
          idOrder: null,
        }
      }
    },
  },
  watch: {
    clientSearch: {
      handler(val){
        const selectedClient = this.companies.find(x => x.name == val)
        if(selectedClient?.name == val) return
        if(val) this.searchClients(this.clientSearch)
      }
    },
    originAddressSearch() {
      this.googleSearch();
    },

    Servicedef: function (val) {
      if (val == 'NA') {
        this.$toast.error("Es necesario elegir un servicio porfavor contacte un asesor")

      }
    }
  },
  data() {
    return {
      date: null,
      filess: [],
      menu2: false,
      hidden: true,
      show: 1,
      showAddCompany: false,
      clientSearch: null,
      business:[],
      valid: true,
      validEnvio: true,
      ClientEnvio: true,
      CargaEnvio: true,
      quotEnvio: true,
      clientSearchLoading: false,
      industries: [],
      LandService: [
        {
          Id: 1,
          Description: 'Consolidado',
          Status: 1
        },

        {
          Id: 2,
          Description: 'Dedicado',
          Status: 1
        },

        {
          Id: 3,
          Description: 'Hot Shot',
          Status: 1
        },
      ],
      NamePricer: "",
      nameRules: [
        v => !!v || 'Comodity es requerido',
      ],
      transporteMap: "",
      transport: "",
      transportation: "Maritimo",
      serviceMap: "",
      IncotermMap: "",
      tipoEnvio: "",
      Employees: [],
      companies: [],
      Servicios: [],
      resulImage: "",
      image00000: 1,
      image00001: 0,
      image00011: 0,
      image00101: 0,
      tab: null,
      Pickup: 0,
      Delivery: 0,
      PortFees: 0,
      Customclerance: 0,
      Logistic: 0,
      Insurance: 0,
      switch1: "",
      toggle_exclusive: undefined,
      ex11: false,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      filterOptions: false,
      origin: {},
      destination: {},
      markers: [],
      originAddress: {},
      TypeRoute: ['Importacion', 'Exportacion'],
      RangePrices: ['1- 100 USD', '100 - 300 USD', '300 - 1000', '> 1000'],
      commodities: ['Agricultura', 'Alimentación', 'Comercio', 'Construcción', 'Educación'],
      price: 0,
      commodity: '',
      portsOfLoading: [],
      Incoterms: [],
      Pol: {
        name: null,
        city: null,
        coordinates: null,
        country: null
      },
      Pod: {
        name: null,
        city: null,
        coordinates: null,
        country: null
      },
      detailorder: null,
      LoadingTypes: [],
      address: '',
      address2: '',
      Destiny: null,

      menu: false,
      Incoterm: "",
      transportedef: "",

      orders: {
        businessId:1,
        DeliveryTypeId:  1,
        transportationId: null,
        transportationCategoryId: null,
        orderTypeId: null,
        assignToPricer: true,
        origin: [{
          address: null,
          city: null,
          state: null,
          country: null,
          coordinates: null
        }],
        destination: [{
          address: null,
          state: null,
          city: null,
          country: null,
          coordinates: null
        }],
        pol: null,
        pod: null,
        departureDate: null,
        industryId: null,
        budget: null,
        commodity: null,
        LandTransfer: false,
        LandServiceId: null,
        insideBillOfLanding: false,
        cargo: [],
        Services: [],
        clientId: null,
        pricerId: null,
        Frequency: null,
        urgent: false,
        comments: null,
        documents: [],
        documentsDrag: []
      },
      TypeRoutedef: "",
      transporte: ['Aereo', 'Maritimo', 'Terrestre'],
      Servicedef: "",
      Service: ['LCL', 'FCL', 'Bulk', 'NA'],
      items: [
        {title: 'Click Me'},
        {title: 'Click Me'},
        {title: 'Click Me'},
        {title: 'Click Me 2'},
      ],

      expand: false,
      slider: 45,
      rules: {
        txtMaxLenght: value => (value || '').length <= 200 || 'Description must be 200 characters or less'
      },
    }
  },
  methods: {
    searchClients: debouncePromise(function (search){
      if (search === '') {
        return
      }
      this.getClients(search)
    }, 300),
    getClients(search) {
      this.companies = []
      this.clientSearchLoading = true
      this.$http.get(`Companies/find`,{
            params: {
              name: search,
            }
          }
      ).then((response) => {
        console.log(response)
        this.clientSearchLoading = false
        let aux = response.data.data
        if (aux === null){
          console.log(aux)
        } else {
          aux.forEach((element) =>{
            this.companies.push(element)
          })
        }
      })
    },
    getBuissnes() {
      this.$store.dispatch("working", true);
      this.$http("Business").then((response) => {
        this.business = response.data.data;
        console.log(this.business)
      }).catch((error) => {
        console.log(error);
        this.$toast.error("Algo salio mal, contacte al administrador!");
      }).finally(()=>{
        this.$store.dispatch("working", false);
      })

    },


    getServiceFields(data) {
      console.log(data)
      this.orders.goodsCost = parseInt(data.goodsCost)

    },

    ConvertFile: async function () {

      let documents = this.orders.documentsDrag;

      for (const doc of documents) {
        const result = await this.UploadFile(doc);
        const data = result.split(',')[1];
        const dotPosition = doc.name.lastIndexOf('.');
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName = dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);
        this.orders.documents.push(
            {
              documentTypeId: 103,
              file: data,
              fileName: fileName,
              fileExtension: fileExt
            }
        )
      }
      console.log(this.orders)
    },
    UploadFile: function (file) {
      return new Promise((resolve, reject) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error);
      });
    },

    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */

    txtMaxLenght: function (e) {
      this.txtAreaLenght = this.orders.comments.length
      this.txtAreaLenght >= this.txtAreaMax ? this.orders.comments = this.orders.comments.slice(0, this.txtAreaMax) : ''
      if (this.txtAreaLenght === this.txtAreaMax) {
        if (e.keyCode !== 8 && e.keyCode !== 67 && e.keyCode !== 37 && e.keyCode !== 38 && e.keyCode !== 39 && e.keyCode !== 40) {
          if (e.keyCode !== 17) {
            e.preventDefault()
          } else {
            if (e.keyCode === 67) {
              this.orders.comments = document.execCommand('copy')
            }
          }
          e.preventDefault()
        }
      }
    },

    recibeData: function (servicesData) {
      console.log("servicio recibido", servicesData);
      this.orders.Services = servicesData;
      if (this.orders.services?.length > 0) {
        this.orders.Services.forEach(item => {
          let service = this.orders.services.find(el => item.ServiceId === el.serviceId);
          if (service) {
            item.id = service.id;
          }
        })
      }
    },

    loadingData: function (loadingData) {
      console.log("Kboom", JSON.stringify(loadingData));
      this.orders.cargo = [];
      loadingData.forEach(element => {
        this.orders.cargo.push(
            {
              Id: element.id ?? 0,
              stackable: element.stackable,
              tiltable: element.tiltable,
              totalWeight: element.totalWeight,
              containerId: element.containerId > 0 ? element.containerId : null,
              type: element.type,
              quantity: element.quantity,
              UnitMeasure: element.unitMeasure,
              WeightUnitMeasure: element.weightUnitMeasure,
              weight: element.weight,
              loadingTypeId: element.loadingTypeId > 0 ? element.loadingTypeId : null,
              dangerous: element.dangerous,
              metrics: element.metrics,
              large: element.large ?? "1",
              width: element.width ?? "1",
              legal: element.legal,
              height: element.height ?? "1",
              Unit: element.weightUnitMeasure,
              messageWeigth: '',
              documents: element.documents ?? [],
              landFull: element.landFull,
            }
        )
      })
      console.log("Kboom", JSON.stringify(this.orders.cargo));
      // this.orders.cargo = loadingData
    },

    QuotDataReceive: function () {

    },

    lastNameEvent: function (value) {
      console.log(value)
    },

    test: function (data) {
      console.log("a", data);
    },


    getPricer: function () {
      this.$store.dispatch('working', true)

      this.$http.get('Employees/Pricing')
          .then(response => {
            if(this.orders.pricerId === null)
              this.Employees = response.data.data.filter(item => item.email === 'julianI@senator-int.com');
            else
              this.Employees = response.data.data;

            console.log("=>(Terrestre.vue:733) this.Employees", this.Employees);
          })
          .catch(error => {
            console.log(error)
          }).finally(() => this.$store.dispatch('working', false))
    },


    getIndustries: function () {
      this.$store.dispatch('working', true)

      this.$http.get('Industries')
          .then(response => {
            this.industries = response.data.data;
          })
          .catch(error => {
            console.log(error)
          }).finally(() => this.$store.dispatch('working', false))
    },

    SetDefaultOrder() {
      this.Origin = {};
      this.Destination = {};
      this.orders = {
        transportationId: null,
        transportationCategoryId: null,
        assignToPricer: true,
        orderTypeId: null,
        origin: [{
          address: null,
          city: null,
          state: null,
          country: null,
          coordinates: null
        }],
        destination: [{
          address: null,
          state: null,
          city: null,
          country: null,
          coordinates: null
        }],
        pol: null,
        pod: null,
        departureDate: null,
        industryId: null,
        budget: null,
        commodity: null,
        LandTransfer: false,
        insideBillOfLanding: false,
        DeliveryTypeId:  1,
        cargo: [],
        Services: [],
        clientId: null,
        pricerId: null,
        Frequency: null,
        urgent: false,
        comments: null
      }
    },


    NewOrder: function () {
      delete this.orders.orderStatusLifetime
      if (this.Typetransportation === null) {
        this.$toast.error("Porfavor llena un Transporte principal");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.TypeService === null) {
        this.$toast.error("Porfavor llena la categoria del transporte");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.Type === null) {
        this.$toast.error("Sera impo o expo?");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.orders.clientId === null) {
        this.$toast.error("Porfavor llena un Cliente!");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.orders.industryId === null) {
        this.$toast.error("Porfavor llena una industria!");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.orders.commodity === null) {
        this.$toast.error("Porfavor llena un commodity");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.orders.businessId === null) {
        this.$toast.error("Porfavor llena una Empresa!");
        this.$store.dispatch("working", false);
        return;
      }
      this.orders.overload = false;
      this.orders.oversize = false;
      this.orders.transportationId = this.Typetransportation;
      this.orders.transportationCategoryId = this.TypeService;
      this.orders.orderTypeId = this.Type;
      this.orders.incotermId = this.Inconterm;

      this.orders.departureDate = this.dueDate;

      if (this.orders.insideBillOfLanding == "true")
        this.orders.insideBillOfLanding = true;

      if (this.orders.insideBillOfLanding == "false")
        this.orders.insideBillOfLanding = false;

      this.orders.cargo.forEach(element => {
        if (!element.dangerous) {
          delete element.documents
        }
        if (element.dangerous && element.documents) {
          element.documents.forEach(data => {
            if (data.file == null) {
              let index = element.documents.findIndex(index => index.file == data.file);
              element.documents.splice(index, 1);
            }
          })
        }

        if (element.type == "Container"){
          element.loadingTypeId = null
          // element.height = 0;
          // element.large = 0;
          // element.width = 0;
        }

        if (element.type=="Pallets"){
          element.containerId = null;
          element.loadingTypeId = 2;
        }

      })

      this.orders.DeliveryTypeId = this.filterRoute;

      let dataSend = JSON.parse(JSON.stringify(this.orders));
      delete dataSend.services;
      delete dataSend.transportation;
      delete dataSend.transportationCategory;
      delete dataSend.industry;
      delete dataSend.frequency;
      delete dataSend.currency;
      delete dataSend.orderType;
      delete dataSend.client;
      delete dataSend.salesManager;
      delete dataSend.pricer;
      delete dataSend.history;
      delete dataSend.documentsDrag;

      if(this.Edit.edit) {
        this.orders.overload = false;
        this.orders.oversize = false;

        if(
            this.$refs.envio.validate()
            && this.$refs.cliente.validate()
            && this.$refs.carga.validate()
        ) {
          this.orders.pol = null
          this.orders.pod = null
          console.log("UpdateOrder")
          this.$emit("UpdateOrder", JSON.parse(JSON.stringify(this.orders)));
        }
        else{
          this.$toast.error('Llene los campos requeridos');
        }
        return
      }

      console.log(this.orders)

      this.$refs.envio.validate();
      this.$refs.cliente.validate();
      this.$refs.carga.validate();
      this.$refs.quotData.validate();
      this.orders.DeliveryTypeId = 1;

      console.log(this.$refs.envio)
      console.log(this.$refs.cliente)
      console.log(this.$refs.carga)
      console.log(this.$refs.quotData)
      console.log(this.Origin)
      console.log(this.Destination)

      if (this.$refs.envio.validate() &&
          this.$refs.cliente.validate() &&
          this.$refs.carga.validate() &&
          this.$refs.quotData.validate() &&
          Object.keys(this.Origin).length > 0 &&
          Object.keys(this.Destination).length > 0) {
        // this.$store.dispatch("working", true);
        if (this.orders.LandServiceId == null)
          delete this.orders.LandServiceId

        if (this.orders.budget == null || this.orders.budget == "")
          delete this.orders.budget;
        this.orders.ExpirationDate = this.date
        this.orders.origin[0].country = this.Origin.country
        this.orders.origin[0].city = this.Origin.city
        this.orders.origin[0].address = this.Origin.address
        this.orders.origin[0].state = this.Origin.state
        this.orders.origin[0].coordinates = `${this.Origin.coordinates.lat},${this.Origin.coordinates.lng}`

        this.orders.destination[0].country = this.Destination.country
        this.orders.destination[0].city = this.Destination.city
        this.orders.destination[0].address = this.Destination.address
        this.orders.destination[0].state = this.Destination.state
        this.orders.destination[0].coordinates = `${this.Destination.coordinates.lat},${this.Destination.coordinates.lng}`


        this.orders.transportationId = this.Typetransportation;
        this.orders.transportationCategoryId = this.TypeService;
        this.orders.orderTypeId = this.Type;
        this.orders.departureDate = this.dueDate;
        this.orders.incotermId = 1;


        if (this.orders.insideBillOfLanding == "true")
          this.orders.insideBillOfLanding = true;

        if (this.orders.insideBillOfLanding == "false")
          this.orders.insideBillOfLanding = false;

        this.orders.cargo.forEach(element => {
          if (!element.dangerous) {
            delete element.documents
          }
          if (element.dangerous) {
            element.documents.forEach(data => {
              if (data.file == null) {
                let index = element.documents.findIndex(index => index.file == data.file);
                element.documents.splice(index, 1);
              }
            })
          }


          if (element.type == "Container") {
            element.loadingTypeId = null
            element.height = 0;
            element.width = 0;
            element.large = 0;
          }

          if (element.type == "Pallets") {
            element.containerId = null;
            element.loadingTypeId = 2;
          }
        })

        this.$store.dispatch('working', true);

        let dataSend = JSON.parse(JSON.stringify(this.orders));

        // Update all cargo
        // if (dataSend.transportationCategoryId !== 2) {
        //   dataSend.cargo.forEach((item) => {
        //     console.log("Aqui esta",item);
        //     // Convertir a Libras el peso
        //     if (item.WeightUnitMeasure === 'Kg') {
        //       item.weight = item.weight * 2.205
        //     }
        //     if (item.WeightUnitMeasure === 'Gr') {
        //       item.weight = item.weight / 454
        //     }
        //     if (item.WeightUnitMeasure === 'Ton') {
        //       item.weight = item.weight * 2205
        //     }
        //     if (item.WeightUnitMeasure === 'Oz') {
        //       item.weight = item.weight / 16
        //     }
        //
        //     // Convertir a Pulgadas
        //     if(item.UnitMeasure === 'Mts') {
        //       item.height = item.height * 39.37
        //       item.large = item.large * 39.37
        //       item.width = item.width * 39.37
        //     }
        //     if(item.UnitMeasure === 'Cm') {
        //       item.height = item.height / 2.54
        //       item.large = item.large / 2.54
        //       item.width = item.width / 2.54
        //     }
        //     if(item.UnitMeasure === 'Pies') {
        //       item.height = item.height * 12
        //       item.large = item.large * 12
        //       item.width = item.width * 12
        //     }
        //
        //     item.UnitMeasure = "Pulgada"
        //     item.WeightUnitMeasure = "Lb"
        //
        //   })
        // }


        delete dataSend.services;
        delete dataSend.transportation;
        delete dataSend.transportationCategory;
        delete dataSend.industry;
        delete dataSend.frequency;
        delete dataSend.currency;
        delete dataSend.orderType;
        delete dataSend.client;
        delete dataSend.salesManager;
        delete dataSend.pricer;
        delete dataSend.history;
        dataSend.pol = null;
        dataSend.pod = null;

        if (this.Edit.edit) {
          console.log(dataSend)
          this.$http.put(`Orders/${this.Edit.idOrder}`, dataSend).then(response => {

            if (response.data.code === 200) {
              console.log(response.data.data);
              this.$toast.info("Cotizacion Actualizada");
              this.$emit('OrderUpdated');
              // this.$refs.loadDetail.resetCargo();
              //this.SetDefaultOrder();
            } else {
              console.log('No paso')
              this.$toast.error("¡Ups! No pasó tu cotización. Nuestro equipo ya fue notificado")
            }
          }).catch(error => {
            this.$toast.error("¡Ups! No pasó tu cotización. Nuestro equipo ya fue notificado")
            console.log('Update Error', error);
          }).finally(() => {
            this.$store.dispatch('working', false)
          })
        } else if (this.Edit.edit == false) {
          this.$http.post('Orders', dataSend).then(response => {
            this.$store.dispatch('working', true)
            if (response.data.code === 200) {
              console.log(response.data.data);
              this.$toast.success("Cotizacion Enviada a Pricing")
              this.$emit('OrderCreated', response.data.data);
              if (!this.orders.assignToPricer)
                this.$router.replace(`/ProceedQuoteSales/${response.data.data.id}`);
              this.$refs.loadDetailTerrestre.resetCargo();
              this.$refs.servTerrestre.resetServices();
              this.$emit('resetQuotData');
              this.SetDefaultOrder();

            }
          }).catch(error => {
            this.$toast.error("¡Ups! No pasó tu cotización. Nuestro equipo ya fue notificado")
            console.log(error);
          }).finally(() => {
            this.$store.dispatch('working', false)
          })
        }
      } else {
        this.$toast.error('Llene origen o destino');
      }


    },
    getCustomers: async function () {
      try {
        const response = await this.$http.get('Companies');
        if (response && response.data) {
          this.companies = response.data.data;
        } else {
          console.error('Respuesta no válida:', response);
        }
      } catch (error) {
        console.error('Error al obtener clientes:', error);
      }
    },
    updateCustomers: function () {
      console.log('hola')
    },


    defaultCargo: function () {

      if (this.DataService.Container == false) {
        this.orders.cargo = [{
          stackable: false,
          tiltable: false,
          totalWeight: true,
          containerId: null,
          type: null,
          quantity: 1,
          UnitMeasure: null,
          WeightUnitMeasure: null,
          weight: 21630,
          loadingTypeId: null,
          dangerous: false,
          metrics: false,
          large: 0,
          width: 0,
          legal: false,
          height: 0,
          Unit: 2
        }]
      }

      if (this.DataService.Pallets == false) {
        this.orders.cargo = [{
          stackable: false,
          tiltable: false,
          totalWeight: true,
          containerId: null,
          type: null,
          quantity: 1,
          UnitMeasure: null,
          WeightUnitMeasure: null,
          weight: null,
          loadingTypeId: 2,
          dangerous: false,
          metrics: false,
          large: 1,
          width: 1,
          legal: false,
          height: 1,
          Unit: 2
        }]
      }


    },


    SetDataOrder() {
      if (this.DataOrder) {
        this.orders = this.DataOrder;
        this.orders.urgent = this.DataOrder.urgent;
        this.orders.assignToPricer = this.DataOrder.pricerId !== null;
        this.orders.documentsDrag = [];

        console.log('Set Data Order Terrestre');

        // this.orders.cargos = [];
        // this.LoadData.forEach(element => {
        //   this.orders.cargos.push(
        //       {
        //         Id: element.id,
        //         stackable: element.stackable,
        //         tiltable: element.tiltable,
        //         containerId: element.containerId > 0 ? element.containerId : null,
        //         type: element.type,
        //         quantity: element.quantity,
        //         UnitMeasure: element.unitMeasure,
        //         WeightUnitMeasure: element.weightUnitMeasure,
        //         weight: element.weight,
        //         loadingTypeId: element.loadingTypeId > 0 ? element.loadingTypeId : null,
        //         dangerous: element.dangerous,
        //         large: element.large,
        //         width: element.width,
        //         legal: true,
        //         height: element.height,
        //         Unit: element.weightUnitMeasure,
        //         messageWeigth: ''
        //       }
        //   )
        // })
      }
      this.orders.DeliveryTypeId = 1;


    }

  },
  mounted() {
    console.log("=>(Terrestre.vue:1209) this.orders", this.orders);
    this.getCustomers();
    this.getPricer();
    this.QuotDataReceive();
    //this.defaultCargo();
    this.getIndustries();
    this.SetDataOrder();
    this.getBuissnes();
  }
}
</script>

<style scoped>

</style>
